/*------------------------------------*\
    #TOOLKIT
\*------------------------------------*/
import _ from '../utility';
import EventEmitter from './event-emitter';

/**
 * Defaults
 */
const defaults = {
    /**
     * Default class names
     *
     * @type {string}
     */
    classItem: 'js-toolkit-item',
    classTooltip: 'js-toolkit-tooltip',
    classTooltipClose: 'js-toolkit-tooltip-close',
    classTooltipContent: 'js-toolkit-tooltip-content',
    classTooltipArrow: 'js-toolkit-tooltip-arrow',
    classItemActive: 'is-active',
    classTooltipVisible: 'is-visible',

    /**
     * Default data attribute names
     *
     * @type {string}
     */
    dataIdx: 'tooltip-idx',

    /**
     * Vertical tooltip offset
     *
     * @type {number}
     */
    offsetY: 20,
};

export default class Toolkit extends EventEmitter {
    /**
     * @constructor
     * @param {Element} el
     * @param {Object} [options]
     * @return {Instance} this
     */
    constructor(el, options) {
        super();

        // Settings
        this._settings = _.extend({}, defaults, options);

        // Elements
        this.el = el;
        this.elItem = this.el.querySelectorAll(`.${this._settings.classItem}`);
        this.elTooltip = this.el.querySelector(`.${this._settings.classTooltip}`);
        this.elTooltipContent =
            this.el.querySelector(`.${this._settings.classTooltipContent}`);
        this.elTooltipArrow =
            this.el.querySelector(`.${this._settings.classTooltipArrow}`);

        // Props
        this._crtIdx = -1;
        this._propsItem = [];
        this._propsEl = {};

        // Binding
        this._handleClick = this._handleClick.bind(this);
        this._setup = this._setup.bind(this);
        this.hide = this.hide.bind(this);

        this._handleEventListener();
        this._setup();

        return this;
    }

    /**
     * Show tooltip for given idx
     *
     * @param {Integer} idx
     * @return {Instance} object
     */
    show(idx) {
        if (idx < 0 || idx >= this.elItem[idx].length) {
            return this;
        }

        this._setTooltipContent(idx);
        this._setTooltipPos(idx);

        if (this._crtIdx !== -1) {
            this.elItem[this._crtIdx].classList.remove(this._settings.classItemActive);
        }

        this.elItem[idx].classList.add(this._settings.classItemActive);
        this.elTooltip.classList.add(this._settings.classTooltipVisible);

        this._crtIdx = idx;

        return this;
    }

    /**
     * Hide tooltip
     *
     * @return {Instance} object
     */
    hide() {
        if (this._crtIdx === -1) {
            return this;
        }

        this.elTooltip.classList.remove(this._settings.classTooltipVisible);
        this.elItem[this._crtIdx].classList.remove(this._settings.classItemActive);

        this._crtIdx = -1;

        this._emit('hide');

        return this;
    }

    /**
     * Destroy instance
     *
     * @return {Void}
     */
    destroy() {
        this._handleEventListeners(true);

        this._settings = null;
        this._handleClick = null;
        this._handleResize = null;
        this._propsItem = this._propsEl = null;
        this.el = this.elTooltip = this.elTooltipArrow = this.elTooltipContent = null;
    }

    /**
     * @private
     * @return {Integer} idx
     * @return {Void}
     */
    _setTooltipContent(idx) {
        const content =
            `<b class="u-text-upper">${this._propsItem[idx].title}</b>
            <p>${this._propsItem[idx].description}</p>`;

        this.elTooltipContent.innerHTML = content;

        this._emit('show', {
            content,
        });
    }

    /**
     * @private
     * @return {Integer} idx
     * @return {Void}
     */
    _setTooltipPos(idx) {
        const tooltipWidth = this.elTooltip.offsetWidth;
        const tooltipHeight = this.elTooltip.offsetHeight;
        const arrowWidth = this.elTooltipArrow.getBoundingClientRect().width;
        const posTopRelative = this._propsItem[idx].top - this._propsEl.top;
        const posLeftRelative = this._propsItem[idx].left - this._propsEl.left;
        let posTop;
        let posLeft;
        let posArrowTop;
        let posArrowLeft;

        // By default, the tooltip is horizontally centered and positioned above
        // the icon
        posTop = posTopRelative - tooltipHeight - this._settings.offsetY;
        posLeft =
            posLeftRelative - ((tooltipWidth - this._propsItem[idx].width) / 2);
        posArrowTop = tooltipHeight - 2;
        posArrowLeft = (tooltipWidth - arrowWidth) / 2;

        // Adjust horizontal position (if tooltip overflows parent container)
        if (posLeft < 0) {
            posLeft = 0;
            posArrowLeft =
                posLeftRelative + ((this._propsItem[idx].width - arrowWidth) / 2);
        } else if (posLeft + tooltipWidth > this._propsEl.width) {
            const posRight =
                this._propsEl.width - (posLeftRelative + (this._propsItem[idx].width / 2));

            posLeft = this._propsEl.width - tooltipWidth;
            posArrowLeft = tooltipWidth - (posRight + (arrowWidth / 2));
        }

        // Adjust vertical position
        if (posTop < 0) {
            posTop +=
                tooltipHeight + this._propsItem[idx].height + (this._settings.offsetY * 2);
            posArrowTop = 0;
        }

        this.elTooltipArrow.style.transform =
            `translate(${posArrowLeft}px, ${posArrowTop}px) rotate(-45deg)`;
        this.elTooltip.style.transform = `translate(${posLeft}px, ${posTop}px)`;
    }

    /**
     * Fired when user clicks inside `el`
     *
     * @private
     * @param {Object} evt
     * @return {Void}
     */
    _handleClick(evt) {
        if (evt.target.matches(`.${this._settings.classItem},
      .${this._settings.classItem} *`)) {
            const elClosest = evt.target.closest(`.${this._settings.classItem}`);

            this.show(parseInt(elClosest.getAttribute(`data-${this._settings.dataIdx}`), 10));
        } else if (this._crtIdx !== -1) {
            if (!evt.target.matches(`.${this._settings.classTooltip},       .${this._settings.classTooltip} *`) ||
                evt.target.matches(`.${this._settings.classTooltipClose}, .${this._settings.classTooltipClose} *`)) {
                this.hide();
            }
        }
    }

    /**
     * @private
     * @return {Void}
     */
    _setup() {
        let i;

        this._propsEl = this.el.getBoundingClientRect();

        for (i = 0; i < this.elItem.length; i += 1) {
            this._propsItem[i] = this.elItem[i].getBoundingClientRect();
            this._propsItem[i].title = this.elItem[i].querySelector('title').innerHTML;
            this._propsItem[i].description = this.elItem[i].querySelector('desc').innerHTML;

            this.elItem[i].setAttribute(`data-${this._settings.dataIdx}`, i);
        }
    }

    /**
     * Add/Remove event listeners
     *
     * @private
     * @param {boolean} [remove=false]
     * @return {Void}
     */
    _handleEventListener(remove = false) {
        const eventType = remove ? 'removeEventListener' : 'addEventListener';

        document[eventType]('click', this._handleClick);
        window[eventType]('resize', _.debounce(this._setup, 500, this.hide));
    }
}
