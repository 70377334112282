/*------------------------------------*\
    #SCROLL-TO
\*------------------------------------*/
import _ from '../utility';

/**
 * Default settings
 */
const defaults = {
    /**
     * Callback function
     *
     * @type {Function}
     */
    callback: false,

    /**
     * Easing function
     *
     * @param {Integer} t Current time
     * @param {number} b Beginning value
     * @param {number} c Change in value
     * @param {Integer} d Duration
     * @return {number}
     */
    easing: (t, b, c, d) => {
        /*eslint-disable no-param-reassign, no-mixed-operators */
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t -= 1;
        return -c / 2 * (t * (t - 2) - 1) + b;
        /*eslint-enable no-param-reassign, no-mixed-operators */
    },

    /**
     * Scrolling container
     *
     * @type {Object}
     */
    container: window,

    /**
     * Scroll axis (`x`, `y`, `both`)
     *
     * @type {string}
     */
    axis: 'y',

    /**
     * Scroll duration
     *
     * @type {Integer}
     */
    duration: 400,
};

const scrollTo = (() => {
    const initPos = {};
    const distance = {};
    let rAF = null;
    let timeStart;
    let timeElapsed;
    let settings;

    /**
     * @private
     * @param {number} val
     * @return {Void}
     */
    const setScrollLeft = (val) => {
        if (settings.container === window) {
            document.documentElement.scrollLeft = val;
            document.body.scrollLeft = val;
        } else {
            settings.container.scrollLeft = val;
        }
    };

    /**
     * @private
     * @param {number} val
     * @return {Void}
     */
    const setScrollTop = (val) => {
        if (settings.container === window) {
            document.documentElement.scrollTop = val;
            document.body.scrollTop = val;
        } else {
            settings.container.scrollTop = val;
        }
    };

    /**
     * @private
     * @return {number} scrollLeft
     */
    const getScrollLeft = () => {
        if (settings.container === window) {
            return document.documentElement.scrollLeft || document.body.scrollLeft;
        }

        return settings.container.scrollLeft;
    };

    /**
     * @private
     * @return {number} scrollTop
     */
    const getScrollTop = () => {
        if (settings.container === window) {
            return document.documentElement.scrollTop || document.body.scrollTop;
        }

        return settings.container.scrollTop;
    };

    /**
     * Called when scroll animation is finished
     *
     * @private
     * @return {Void}
     */
    const done = () => {
        rAF = null;

        // Account for rounding inaccuracies
        if (settings.axis !== 'y') {
            setScrollLeft(initPos.x + distance.x);
        }

        if (settings.axis !== 'x') {
            setScrollTop(initPos.y + distance.y);
        }

        if (typeof settings.callback === 'function') {
            settings.callback();
        }

        // Reset time for next scroll
        timeStart = false;
    };

    /**
     * Main animation loop
     *
     * @private
     * @param {Integer} timeCurrent
     * @return {Void}
     */
    const loop = (timeCurrent) => {
        if (!timeStart) {
            timeStart = timeCurrent;
        }

        timeElapsed = timeCurrent - timeStart;

        if (settings.axis !== 'y') {
            setScrollLeft(settings.easing(timeElapsed, initPos.x, distance.x, settings.duration));
        }

        if (settings.axis !== 'x') {
            setScrollTop(settings.easing(timeElapsed, initPos.y, distance.y, settings.duration));
        }

        // Check progress
        if (timeElapsed < settings.duration) {
            rAF = requestAnimationFrame(loop);
        } else {
            done();
        }
    };

    /**
     * Scroll to an element or a position
     *
     * @param {string|number|Array|Element} targetX
     * @param {Object} [options]
     * @return {Object} scrollTo
     */
    const start = (target, options = {}) => {
        settings = _.extend({}, defaults, options);

        // Cache initPosing position
        initPos.x = getScrollLeft();
        initPos.y = getScrollTop();

        // Reset distance
        distance.x = 0;
        distance.y = 0;

        if (_.isArray(target)) {
            // Scroll to a given position (Array)
            if (settings.axis !== 'y') {
                distance.x = target[0] - initPos.x;
            }

            if (settings.axis !== 'x') {
                distance.y = target[1] - initPos.y;
            }
        } else if (_.isNumber(target)) {
            // Scroll to a given position (Number)
            if (settings.axis === 'both') {
                distance.x = target - initPos.x;
                distance.y = target - initPos.y;
            } else {
                distance[settings.axis] = target - initPos[settings.axis];
            }
        } else if (_.isElement(target)) {
            // Scroll to an element
            const boundingBox = target.getBoundingClientRect();

            if (settings.axis !== 'y') {
                distance.x = boundingBox.left;
            }

            if (settings.axis !== 'x') {
                distance.y = boundingBox.top;
            }
        } else {
            return false;
        }

        rAF = requestAnimationFrame(loop);

        return scrollTo;
    };

    /**
     * Stop scrolling animation
     *
     * @return {Object} scrollTo
     */
    const stop = () => {
        if (rAF !== null) {
            cancelAnimationFrame(rAF);

            timeStart = false;
        }

        return scrollTo;
    };

    return {
        start,
        stop,
    };
})();

export default scrollTo;
