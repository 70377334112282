/*------------------------------------*\
    #VANNOMADEN-JAVSCRIPT
\*------------------------------------*/
/**
 * Main javascript file that's loaded asynchronously. It is written to the Airbnb standard
 * (https://github.com/airbnb/javascript) and bundled with rollup
 */
import 'whatwg-fetch';
import 'element-closest';
import inView from 'in-view';
import CountUp from 'countup.js';
import Timeago from 'timeago.js';
import Parallax from './modules/parallax';
import './modules/object-fit';
import nav from './modules/nav';
import scrollTo from './modules/scroll-to';
import dialog from './modules/dialog';
import lexicon from './modules/lexicon';
import icon from './modules/icon';
import Toolkit from './modules/toolkit';
import Filter from './modules/filter';

/**
 * Fired when the DOM is loaded
 *
 * @return {Void}
 */
function main() {
    const elNav = document.querySelector('.js-nav');
    const elDialog = document.querySelector('.js-dialog');
    const elLexicon = document.querySelector('.js-lexicon');
    const elRotate = document.querySelector('.js-rotate-landscape');
    let i;
    let isPageScrolled = false;
    let isMobilePortrait = false;
    let showMobilePortraitNotice = true;

    if (elNav) {
        nav.init(elNav, {
            on: (action) => {
                if (action === 'open') {
                    elNav.classList.add('has-enhanced-icons');
                } else if (action === 'close') {
                    elNav.classList.remove('has-enhanced-icons');
                }
            },
        });
    }
    if (elDialog) {
        dialog.init(elDialog);
    }
    if (elLexicon) {
        lexicon.init(elLexicon);
    }
    icon.init();

    /**
     * Fired when mobile protrait media query changes
     *
     * @return {Void}
     */
    function handleMobileProtraitResize(mq) {
        isMobilePortrait = mq.matches;
    }

    const mqMobilePortrait =
        window.matchMedia('only screen and (max-width: 480px) and (orientation: portrait)');

    mqMobilePortrait.addListener(handleMobileProtraitResize);
    handleMobileProtraitResize(mqMobilePortrait);

    /**
     * Fired when user clicks on any element
     *
     * @param {Object} evt
     * @return {Void}
     */
    function handleClick(evt) {
        if (evt.target.matches('.js-scroll-to, .js-scroll-to *')) {
            if (evt.target.tagName === 'A') {
                evt.preventDefault();
            }

            const elClosest = evt.target.closest('.js-scroll-to');
            const dataScrollTo = elClosest.getAttribute('data-scroll-to');

            scrollTo.stop().start(document.getElementById(dataScrollTo));
        } else if (evt.target.matches('.js-toggle-nav, .js-toggle-nav *') && elNav) {
            nav.toggle();
        } else if (evt.target.matches('.js-icon, .js-icon *')) {
            icon.animate(evt.target.closest('.js-icon'));
        } else if (evt.target.matches('.js-swap, .js-swap *')) {
            const elClosest = evt.target.closest('.js-swap');

            elClosest.classList.toggle('is-active');
        } else if (evt.target.matches('.js-open-dialog, .js-open-dialog *') && elDialog) {
            const elClosest = evt.target.closest('.js-open-dialog');

            if (elClosest.tagName === 'A') {
                evt.preventDefault();
            }

            dialog.open({
                type: elClosest.getAttribute('data-dialog-type'),
                data: elClosest.tagName === 'A' ?
                    elClosest.getAttribute('href') :
                    elClosest.getAttribute('data-dialog-data'),
            });
        } else if (evt.target.matches('.js-lexicon-open, .js-lexicon-open *') && elLexicon) {
            const elClosest = evt.target.closest('.js-lexicon-open');

            if (elClosest.tagName === 'A') {
                evt.preventDefault();
            }

            lexicon.open(elClosest);
        }
    }

    /**
     * Fired when page is scrolled
     *
     * @return {Void}
     */
    function handleScroll() {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        if (scrollTop >= 100 && !isPageScrolled) {
            document.body.classList.add('is-page-scrolled');

            if (isMobilePortrait && showMobilePortraitNotice && elRotate) {
                elRotate.classList.add('is-visible');
                showMobilePortraitNotice = false;

                setTimeout(() => {
                    elRotate.classList.remove('is-visible');
                }, 2500);
            }

            isPageScrolled = true;
        } else if (scrollTop <= 100 && isPageScrolled) {
            document.body.classList.remove('is-page-scrolled');

            isPageScrolled = false;
        }
    }

    document.addEventListener('click', handleClick);
    document.addEventListener('scroll', handleScroll);

    handleScroll();

    /**
     * Inview
     */
    const elCount = document.querySelectorAll('.js-count');
    const countInstance = [];

    for (i = 0; i < elCount.length; i += 1) {
        elCount[i].setAttribute('data-count-idx', i);

        countInstance[i] = new CountUp(
            elCount[i], 0, parseInt(elCount[i].textContent, 10), 0, 3);
    }

    inView('.js-count')
        .on('enter', (el) => {
            const idx = el.getAttribute('data-count-idx');

            countInstance[idx].reset();
            countInstance[idx].start();
        });

    inView('.js-icon')
        .on('enter', el => icon.animate(el, true))
        .on('exit', el => icon.reset(el));

    /**
     * Relative time
     */
    const elTime = document.querySelectorAll('.js-time-ago');

    if (elTime) {
        const timeagoInstance = new Timeago();

        timeagoInstance.render(elTime);
    }

    /**
     * Parallax
     */
    const elParallax = document.querySelectorAll('.js-parallax');

    for (i = 0; i < elParallax.length; i += 1) {
        new Parallax(elParallax[i]); // eslint-disable-line
    }

    /**
     * Toolkit
     *
     * Note: only one instance per page for now. Code needs to be adjusted accordingly
     * should this change at some point in the future
     */
    const elToolkit = document.querySelector('.js-toolkit');

    if (elToolkit) {
        const toolkitInstance = new Toolkit(elToolkit);
        const elToolkitDesktop =
            document.getElementById(elToolkit.getAttribute('data-toolkit-desktop'));

        toolkitInstance
            .on('show', (evt) => {
                elToolkitDesktop.innerHTML = evt.content;
            })
            .on('hide', () => {
                elToolkitDesktop.innerHTML = '';
            });
    }

    /**
     * Filter
     */
    const elFilter = document.querySelectorAll('.js-filter');

    for (i = 0; i < elFilter.length; i += 1) {
        new Filter(elFilter[i]); // eslint-disable-line
    }
}

// IE10+
if ('visibilityState' in document) {
    // Simple way to determine if the DOM is ready. Once the DOM has finished traversing and
    // parsing the HTML, it is considered loaded
    if (document.readyState === 'interactive' ||
        document.readyState === 'complete') {
        main();
    } else {
        document.addEventListener('DOMContentLoaded', main);
    }
}
