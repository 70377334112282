/*------------------------------------*\
    #NAV
\*------------------------------------*/
import 'snapsvg';
import _ from '../utility';

/**
 * Default settings
 */
const defaults = {
    /**
     * Default class names
     *
     * @type {string}
     */
    classScroll: 'js-nav-scroll',
    classMorph: 'js-nav-morph',
    classNavOpen: 'is-nav-open',

    /**
     * Public events
     *
     * @return {Function}
     */
    on: () => {},
};
const KEY_ESC = 27;

const nav = (() => {
    let elScroll = null;
    let isOpen = false;
    let settings = null;
    let morph = null;
    let morphInit = null;
    let morphOpen = null;

    /**
     * Toggle navigation modal
     *
     * @return {Object} nav
     */
    const toggle = () => {
        if (isOpen) {
            document.body.classList.remove(settings.classNavOpen);
        } else {
            document.body.classList.add(settings.classNavOpen);

            morph.stop().animate({ path: morphOpen }, 100, () => {
                morph.stop().animate({ path: morphInit }, 700, window.mina.elastic);
            });
        }

        isOpen = !isOpen;

        return nav;
    };

    /**
     * Fired when user presses a key
     *
     * @private
     * @param  {Object} evt
     * @return {Void}
     */
    const handleKeyDown = (evt) => {
        if (!isOpen) {
            return;
        }

        if (evt.keyCode === KEY_ESC) {
            toggle();
        }
    };

    /**
     * Focus element when transition ends
     *
     * @private
     * @param {Object} evt
     * @return {Void}
     */
    const handleTransitionEnd = (evt) => {
        if (evt.propertyName === 'transform') {
            settings.on.call(null, isOpen ? 'open' : 'close');
        }
    };

    /**
     * @param {Element} container
     * @param {Object} [options={}]
     * @return {Object} nav
     */
    const init = (container, options = {}) => {
        settings = _.extend({}, defaults, options);
        elScroll = container.querySelector(`.${settings.classScroll}`);

        const elMorph = document.querySelector(`.${settings.classMorph}`);
        const snapInstance = new window.Snap(elMorph);

        morph = snapInstance.select('#nav-morph-init');
        morphInit = elMorph.querySelector('#nav-morph-init').getAttribute('d');
        morphOpen = elMorph.querySelector('#nav-morph-open').getAttribute('d');

        document.addEventListener('keydown', handleKeyDown);
        container.addEventListener('transitionend', handleTransitionEnd);

        /**
         * Prevent iOS from scrolling the body element. As of late 2016, `overflow: hidden`
         * doesn't work in Safari on the body or html element. In case we want to allow scrolling
         * on an inner nav element, copy the code from the dialog module
         */
        if ('ontouchmove' in document.documentElement) {
            document.documentElement.addEventListener('touchmove', (evt) => {
                if (isOpen) {
                    evt.preventDefault();
                }
            });

            elScroll.addEventListener('touchmove', (evt) => {
                if (isOpen) {
                    evt.stopPropagation();
                }
            });
        }

        return nav;
    };

    return {
        toggle,
        init,
    };
})();

export default nav;
