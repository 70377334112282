/*------------------------------------*\
    #LEXICON
\*------------------------------------*/
import _ from '../utility';

/**
 * Default settings
 */
const defaults = {
    /**
     * Default class names
     *
     * @type {string}
     */
    classInner: 'js-lexicon-inner',
    classBody: 'js-lexicon-body',
    classIcon: 'js-lexicon-icon',
    classClose: 'js-lexicon-close',
    classVisible: 'is-visible',
    classActive: 'is-active',
    classLoading: 'is-loading',
    classLoaded: 'is-loaded',
    classAnimating: 'is-animating',
    classBodyOpen: 'is-lexicon-open',
};

const KEY_TAB = 9;
const KEY_ESC = 27;

const lexicon = (() => {
    let el = null;
    let elContainer = null;
    let elItem = null;
    let elIcon = null;
    let elIconItem = null;
    let elBody = null;
    let elClose = null;
    let elInitialFocus = null;
    let elFocusable = null;
    let settings = null;
    let contentURL = null;
    let isOpen = false;

    const loadAndShowContent = () => {
        if (!contentURL) {
            return;
        }

        const uniqueIdSplit = contentURL.split('/');
        const uniqueId = uniqueIdSplit[uniqueIdSplit.length - 1];
        history.pushState(null, null, uniqueId);
        elContainer.classList.add(settings.classLoading);

        _.loadData(contentURL)
            .then(_.delayPromise(400))
            .then((data) => {
                if (!isOpen) {
                    return;
                }
                const elTemp = document.createElement('div');

                elContainer.classList.remove(settings.classLoading);
                elContainer.classList.add(settings.classLoaded);

                elTemp.innerHTML = data;
                elBody.innerHTML = elTemp.querySelector('.js-lexicon-body').innerHTML;
                elIcon.innerHTML = elTemp.querySelector('.js-lexicon-icon').innerHTML;
            });
    };

    /**
     * Get delta position of multiple elements
     *
     * @param {Array} elsFirst
     * @param {Array} elsLast
     * @private
     * @return {Object}
     */
    const getDeltaOfElements = (elsFirst, elsLast) => {
        const delta = [];

        for (let i = 0; i < elsFirst.length; i += 1) {
            const firstPosition = elsFirst[i].getBoundingClientRect();
            const lastPosition = elsLast[i].getBoundingClientRect();

            delta[i] = {
                x: firstPosition.left - lastPosition.left,
                y: firstPosition.top - lastPosition.top,
                sx: firstPosition.width / lastPosition.width,
                sy: firstPosition.height / lastPosition.height,
            };
        }

        return delta;
    };

    /**
     * Open Lexicon
     *
     * @param {Object} options
     * @return {Object}
     */
    const open = (item) => {
        if (isOpen || elContainer.classList.contains(settings.classAnimating)) {
            return lexicon;
        }

        contentURL = item.getAttribute('href');
        elItem = item;
        elIconItem = item.querySelector(`.${settings.classIcon}`);

        elIcon.innerHTML = elIconItem.innerHTML;
        elBody.innerHTML = '';

        requestAnimationFrame(() => {
            const [deltaEl, deltaIcon] = getDeltaOfElements([elItem, elIconItem], [el, elIcon]);

            el.style.willChange = 'transform';
            elIcon.style.willChange = 'transform';
            el.style.transformOrigin = '0px 0px 0px';
            elIcon.style.transformOrigin = '0px 0px 0px';
            el.style.transform =
                `translate(${deltaEl.x}px, ${deltaEl.y}px) scale(${deltaEl.sx}, ${deltaEl.sy})`;
            elIcon.style.transform =
                `translate(${deltaIcon.x}px, ${deltaIcon.y}px) scale(${deltaIcon.sx}, ${deltaIcon.sy})`;

            elInitialFocus = document.activeElement;
            isOpen = true;

            requestAnimationFrame(() => {
                document.body.classList.add(settings.classBodyOpen);
                elContainer.classList.add(settings.classAnimating);
                elContainer.classList.add(settings.classVisible);
                elContainer.classList.add(settings.classActive);

                el.style.transform = '';
                elIcon.style.transform = '';
            });
        });

        return lexicon;
    };

    /**
     * Close dialog
     *
     * @return {dialog}
     */
    const close = () => {
        if (!isOpen || elContainer.classList.contains(settings.classAnimating)) {
            return lexicon;
        }

        elContainer.classList.remove(settings.classLoaded);

        elIcon.innerHTML = elIconItem.innerHTML;
        contentURL = false;
        isOpen = false;

        history.back();

        requestAnimationFrame(() => {
            const [deltaEl, deltaIcon] = getDeltaOfElements([elItem, elIconItem], [el, elIcon]);

            requestAnimationFrame(() => {
                elContainer.classList.add(settings.classAnimating);
                elContainer.classList.remove(settings.classActive);

                el.style.transform =
                    `translate(${deltaEl.x}px, ${deltaEl.y}px) scale(${deltaEl.sx}, ${deltaEl.sy})`;
                elIcon.style.transform =
                    `translate(${deltaIcon.x}px, ${deltaIcon.y}px) scale(${deltaIcon.sx}, ${deltaIcon.sy})`;
            });
        });

        return lexicon;
    };

    /**
     * Fired when user presses a key
     *
     * @private
     * @param  {Object} evt
     * @return {Void}
     */
    const handleKeyDown = (evt) => {
        if (!isOpen) {
            return;
        }

        if (evt.keyCode === KEY_TAB) {
            const focusableLength = elFocusable.length - 1;

            if (focusableLength < 2) {
                evt.preventDefault();
            }

            if (evt.shiftKey) {
                if (document.activeElement === elFocusable[0]) {
                    elFocusable[focusableLength].focus();

                    evt.preventDefault();
                }
            } else if (document.activeElement === elFocusable[focusableLength]) {
                elFocusable[0].focus();

                evt.preventDefault();
            }
        } else if (evt.keyCode === KEY_ESC) {
            close();
        }
    };

    /**
     * Focus element when transition ends
     *
     * @private
     * @param {Object} evt
     * @return {Void}
     */
    const handleTransitionEnd = (evt) => {
        if (evt.target.classList.contains(settings.classInner)) {
            elContainer.classList.remove(settings.classAnimating);

            if (isOpen) {
                loadAndShowContent();

                elFocusable = elContainer.querySelectorAll(
                    `a[href], area[href], input:not([disabled]),
                    select:not([disabled]), textarea:not([disabled]),
                    button:not([disabled]), [tabindex="0"]`,
                );

                elFocusable[0].focus();
            } else {
                elContainer.classList.remove(settings.classVisible);
                document.body.classList.remove(settings.classBodyOpen);

                el.style.transformOrigin = '';
                elIcon.style.transformOrigin = '';
                el.style.transform = '';
                elIcon.style.transform = '';
                elIcon.innerHTML = '';
                el.style.willChange = '';
                elIcon.style.willChange = '';

                if (elInitialFocus) {
                    elInitialFocus.focus();
                }

                elItem = null;
                elIconItem = null;
                elInitialFocus = null;
                elFocusable = null;
            }
        }
    };

    /**
     * @param {Object} [options]
     * @return {Object}
     */
    const init = (container, options = {}) => {
        settings = _.extend({}, defaults, options);

        elContainer = container;
        el = elContainer.querySelector(`.${settings.classInner}`);
        elIcon = elContainer.querySelector(`.${settings.classIcon}`);
        elBody = elContainer.querySelector(`.${settings.classBody}`);
        elClose = elContainer.querySelector(`.${settings.classClose}`);

        elClose.addEventListener('click', close);
        el.addEventListener('transitionend', handleTransitionEnd);
        document.addEventListener('keydown', handleKeyDown);
        window.addEventListener('popstate', () => {
            if (isOpen) {
                close(false);
            }
        });

        /**
         * Prevent iOS from scrolling the body element. As of late 2016, `overflow: hidden`
         * doesn't work in Safari on the body or html element
         */
        if ('ontouchmove' in document.documentElement) {
            document.documentElement.addEventListener('touchmove', (evt) => {
                if (isOpen) {
                    evt.preventDefault();
                }
            });

            elBody.addEventListener('touchmove', (evt) => {
                if (isOpen) {
                    evt.stopPropagation();
                }
            });
        }

        return lexicon;
    };

    return {
        init,
        open,
        close,
    };
})();

export default lexicon;
