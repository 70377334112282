/*------------------------------------*\
    #OBJECT-FIT
\*------------------------------------*/
/**
 * Simple polyfill (only `<img>` elements) for the CSS property `object-fit` that isn't yet
 * supported in IE (including Edge)
 */
import _ from '../utility';

(() => {
    if ('objectFit' in document.documentElement.style) {
        return;
    }

    const elObjectFit = document.querySelectorAll('img[data-object-fit]');
    const arrSrc = [].map.call(
        elObjectFit,
        elImg => ('currentSrc' in elImg ? elImg.currentSrc : elImg.src),
    );

    // Wait for all images to load before running the polyfill
    _.checkImages.apply(null, arrSrc).then((dataImg) => {
        let fitType;
        let alignImg;
        let i;

        for (i = 0; i < dataImg.length; i += 1) {
            fitType = elObjectFit[i].getAttribute('data-object-fit');
            alignImg = elObjectFit[i].getAttribute('data-object-position');

            if (!alignImg) {
                alignImg = 'center center';
            }

            if (dataImg[i] && fitType) {
                elObjectFit[i].setAttribute('srcset', '');
                elObjectFit[i].style.background =
                    `url("${dataImg[i].src} ") ${alignImg} / ${fitType} no-repeat`;
                elObjectFit[i].src =
                    `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="${dataImg[i].width}" height="${dataImg[i].height}"%3E%3C/svg%3E`; // eslint-disable-line
            }
        }
    });
})();
