/*------------------------------------*\
    #PARALLAX
\*------------------------------------*/
import _ from '../utility';

/**
 * Default settings
 */
const defaults = {
    /**
     * Default class names
     *
     * @type {string}
     */
    classItem: 'js-parallax-item',

    /**
     * Default data attribute names, without the `data-` prefix
     *
     * @type {string}
     */
    dataDepth: 'parallax-depth',
    dataDirection: 'parallax-direction',
};

export default class Parallax {
    /**
     * @param {Element} el
     * @param {Object} options
     * @return {Object} this Parralax instance
     */
    constructor(el, options) {
        // Props
        this._scrollY = 0;
        this._startTop = 0;
        this._ticking = false;
        this._itemData = [];

        // Settings
        this._settings = _.extend({}, defaults, options);

        // Elements
        this.el = el;
        this.elItems = this.el.querySelectorAll(`.${this._settings.classItem}`);

        // Binding
        this._handleScroll = this._handleScroll.bind(this);
        this._handleResize = this._handleResize.bind(this);
        this._update = this._update.bind(this);

        this._setup();
        this._addEventListeners();

        return this;
    }

    /**
     * @private
     * @return {Void}
     */
    _addEventListeners() {
        window.addEventListener('scroll', this._handleScroll);
        window.addEventListener('resize', _.debounce(this._handleResize, 200));
    }

    /**
     * Cache depth and direction of every parallax item
     *
     * @private
     * @return {Void}
     */
    _setup() {
        let depth;
        let direction;
        let i;

        for (i = 0; i < this.elItems.length; i += 1) {
            depth = parseFloat(
                this.elItems[i].getAttribute(`data-${this._settings.dataDepth}`)) || 0;

            direction =
                this.elItems[i].getAttribute(`data-${this._settings.dataDirection}`) || 'down';

            this._itemData[i] = {
                depth,
                direction,
            };
        }
    }

    /**
     * Debounced update event, fired on scroll
     *
     * @private
     * @return {Void}
     */
    _update() {
        let i;
        let translate;

        for (i = 0; i < this.elItems.length; i += 1) {
            translate = (this._scrollY - this._startTop) * this._itemData[i].depth;

            if (this._itemData[i].direction === 'up') {
                translate *= -1;
            }

            this.elItems[i].style.transform = `translate3d(0, ${translate}px, 0)`;
        }

        // Reset tick, so we can capture the next scroll event
        this._ticking = false;
    }

    /**
     * Fired whenever browser window resizes
     *
     * @private
     * @param {Object} evt
     * @return {Void}
     */
    _handleResize() {
        this._startTop =
            this.el.offsetTop - ((window.innerHeight - this.el.offsetHeight) / 2) - 40;

        this._update();
    }

    /**
     * Fired whenever user scrolls
     *
     * @private
     * @param {Object} evt
     * @return {Void}
     */
    _handleScroll() {
        this._scrollY = Math.max(window.scrollY, 0);

        // Request tick
        if (!this._ticking) {
            requestAnimationFrame(this._update);
        }

        this._ticking = true;
    }
}
