/*------------------------------------*\
    #EVENT-EMITTER
\*------------------------------------*/
/**
 * Emit custom JavaScript events by extending the `EventEmitter` class and then call the `_emit()`
 * method
 *
 * class Test extends EventEmitter {
 *     method() {
 *         this._emit('event-name', {
 *             param: 'test',
 *         });
 *     }
 * }
 *
 * const testInstance = new Test();
 *
 * testInstance.on('event-name', (evt) => {});
 * testInstance.off('event-name');
 */
import _ from '../utility';

export default class EventEmitter {
    /**
     * @param {Element} el
     * @param {Object}  [options={}]
     */
    constructor() {
        this._handlers = {};

        return this;
    }

    /**
     * Allow developers to bind custom events
     *
     * @param {string} events/s
     * @param {Function} handler
     * @return {Object} instance
     */
    on(events, handler) {
        const eventsArr = events.split(' ');
        let i;

        for (i = 0; i < eventsArr.length; i += 1) {
            this._handlers[eventsArr[i]] = handler;
        }

        return this;
    }

    /**
     * Allow developers to unbind previously bound events
     *
     * @param {string} event/s
     * @return {Object} instance
     */
    off(events) {
        const eventsArr = events.split(' ');
        let i;

        for (i = 0; i < eventsArr.length; i += 1) {
            this._handlers[eventsArr[i]] = null;
        }

        return this;
    }

    /**
     * Emit custom events that were added with the `on()` method
     *
     * @private
     * @param {string} name
     * @param {Object} [data]
     * @return {Void}
     */
    _emit(name, data = {}) {
        if (this._handlers[name] && _.isFunction(this._handlers[name])) {
            data.type = name; // eslint-disable-line

            this._handlers[name].call(this, data);
        }
    }
}
