/*------------------------------------*\
    #ICON
\*------------------------------------*/
import _ from '../utility';

/**
 * Default settings
 */
const defaults = {
    /**
     * Default class names
     *
     * @type {string}
     */
    classIcon: 'js-icon',
    classPath: 'js-icon-path',
    classEnhanced: 'is-enhanced',

    /**
     * Default data names
     *
     * @type {string}
     */
    dataDelay: 'icon-delay',
};

/**
 * Check if SVG has class. Ugly workaround for IE10+ which supports `classList`
 * but not on SVG elements
 *
 * @param {Element} el
 * @param {String} name
 * @return {boolean}
 */
function hasClass(el, name) {
    return el.getAttribute('class').match(new RegExp(`(\\s|^)${name}(\\s|$)`));
}

/**
 * Add class to SVG. Ugly workaround for IE10+ which supports `classList` but not
 * on SVG elements.
 *
 * @param {Element} el
 * @param {String} name
 * @return {boolean}
 */
function addClass(el, name) {
    if (!hasClass(el, name)) {
        let crtClass = el.getAttribute('class');
        crtClass += ` ${name}`;

        // eslint-disable-next-line
        el.setAttribute('class', crtClass);
    }
}

/**
 * Remove class from SVG. Ugly workaround for IE10+ which supports `classList` but not
 * on SVG elements.
 *
 * @param {Element} el
 * @param {String} name
 * @return {boolean}
 */
function removeClass(el, name) {
    if (hasClass(el, name)) {
        const regEx = new RegExp(`(\\s|^)${name}(\\s|$)`);
        // eslint-disable-next-line
        el.setAttribute('class', el.getAttribute('class').replace(regEx, ' '));
    }
}

const icon = (() => {
    let settings = null;

    /**
     * Reset icon
     *
     * @param {Element} el SVG Icon
     * @return {Object} icon
     */
    const reset = (el) => {
        removeClass(el, settings.classEnhanced);

        return icon;
    };

    /**
     * Animate icon
     *
     * @param {Element} el SVG Icon
     * @param {boolean} instant
     * @return {Object} icon
     */
    const animate = (el, instant = false) => {
        reset(el);

        setTimeout(() => addClass(el, settings.classEnhanced), instant ? 0 : 500);

        return icon;
    };

    /**
     * Set path lengths
     *
     * @private
     * @return {Void}
     */
    const setup = () => {
        const elStroke = document.querySelectorAll(`.${settings.classPath}`);
        let pathLength;
        let i;

        for (i = 0; i < elStroke.length; i += 1) {
            pathLength = elStroke[i].getTotalLength();

            elStroke[i].style.strokeDasharray = pathLength;
            elStroke[i].style.strokeDashoffset = pathLength;
        }
    };

    /**
     * @param {Object} [options={}]
     * @return {Object} icon
     */
    const init = (options = {}) => {
        settings = _.extend({}, defaults, options);

        setup();

        return icon;
    };

    return {
        reset,
        animate,
        init,
    };
})();

export default icon;
