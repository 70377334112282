/*------------------------------------*\
    #UTILITY-FUNCTIONS
\*------------------------------------*/
/**
 * Small set of useful utility and helper functions. Partly based on
 * http://blog.wearecolony.com/a-year-without-jquery/
 */
const _ = {};

/**
 * Check if parameter is an array
 *
 * @param  {*} o
 * @return {boolean}
 */
_.isArray = function isArray(o) {
    return Array.isArray(o);
};

/**
 * Check if parameter is an array
 *
 * @param  {*} o
 * @return {boolean}
 */
_.isObject = function isObject(o) {
    return o !== null && typeof o === 'object';
};

/**
 * Check if parameter is a number
 *
 * @param  {*} o
 * @return {boolean}
 */
_.isNumber = function isNumber(o) {
    return !isNaN(parseFloat(o));
};

/**
 * Check if parameter is an element
 *
 * @param  {*} o
 * @return {boolean}
 */
_.isElement = function isElement(o) {
    return typeof o === 'object' && o instanceof Element;
};

/**
 * Check if parameter is a function
 *
 * @param  {*} o
 * @return {boolean}
 */
_.isFunction = function isFunction(o) {
    return !!(o && o.constructor && o.call && o.apply);
};

/**
 * Check if parameter is a string
 *
 * @param  {*} o
 * @return {boolean}
 */
_.isString = function isString(o) {
    return typeof o === 'string' || o instanceof String;
};

/**
 * Check if parameter is a node
 *
 * @param  {*} o
 * @return {boolean}
 */
_.isNode = function isNode(o) {
    return o && o.nodeType;
};

/**
 * Copy the values of all enumerable own properties from one or more source objects to a
 * target object
 *
 * @param  {Object} target
 * @return {Object} output
 */
_.extend = function extend(target, ...args) {
    const output = Object(target);

    Object.keys(args).forEach((arg) => {
        if (args[arg] !== undefined && args[arg] !== null) {
            Object.keys(args[arg]).forEach((key) => {
                output[key] = args[arg][key];
            });
        }
    });

    return output;
};

/**
 * Check if one image is loaded
 *
 * @param  {string} path
 * @return {Promise.<{{path: String, status: String}}>}
 */
const checkImage = path =>
    new Promise((resolve) => {
        const img = new Image();

        img.onload = () => resolve(img);
        img.onerror = () => resolve(false);

        img.src = path;
    });

/**
 * Check if one or multiple images are loaded
 *
 * @param  {...string} paths
 * @return {Promise.<{{path: String, status: String}}>}
 */
_.checkImages = function checkImages(...paths) {
    return Promise.all(paths.map(checkImage));
};

/**
 * Helper function to throttle events. This function will not fire immediately and wait the
 * specified time before firing.
 *
 * @param {Function} func
 * @param {number} [wait=200]
 * @return {Void}
 */
_.debounce = function debounce(func, wait = 200, funcFirst = false) {
    let timeout;
    let context;
    let args;
    let timestamp;
    let isFuncFirstCalled;

    return function debounceInner(...params) {
        // save details of latest call
        context = this;
        args = params;
        timestamp = new Date();

        const later = function later() {
            // how long ago was the last call
            const last = (new Date()) - timestamp;

            if (last < wait) {
                // if the latest call was less that the wait period ago then we reset the timeout
                // to wait for the difference
                timeout = setTimeout(later, wait - last);
            } else {
                isFuncFirstCalled = false;
                timeout = null;
                func.apply(context, args);
            }
        };

        if (!isFuncFirstCalled && funcFirst) {
            funcFirst.apply(context, args);

            isFuncFirstCalled = true;
        }

        // we only need to set the timer if one isn't already running
        if (!timeout) {
            timeout = setTimeout(later, wait);
        }
    };
};

/**
 * Delay promise
 *
 * @param {integer} delay
 */
_.delayPromise = function delayPromise(delay) {
    return function promiseReturn(data) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(data), delay);
        });
    };
};

/**
 * Load text or JSON data from URL
 *
 * @param {string} url
 * @param {Object} [options={}]
 * @param {string} [options.type='text']
 * @param {config} [options.config={}]
 * @return {Promise}
 */
_.loadData = function loadData(url, { type = 'text', config = {} } = {}) {
    return fetch(url, config).then((response) => {
        if (type === 'json') {
            return response.json();
        } else if (type === 'blob') {
            return response.blob();
        } else if (type === 'text') {
            return response.text();
        }

        return false;
    });
};

export default _;
