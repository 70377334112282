/*------------------------------------*\
    #DIALOG
\*------------------------------------*/
import _ from '../utility';

/**
 * Default settings
 */
const defaults = {
    /**
     * Default class names
     *
     * @type {string}
     */
    classInner: 'js-dialog-inner',
    classClose: 'js-dialog-close',
    classOpen: 'is-open',
    classTypeIframe: 'has-iframe',
    classTypeText: 'has-text',
    classTypeHtml: 'has-html',
    classBodyOpen: 'is-dialog-open',
    classLoading: 'is-loading',
};
const KEY_TAB = 9;
const KEY_ESC = 27;

const dialog = (() => {
    let el = null;
    let elInner = null;
    let elInitialFocus = null;
    let elFocusable = null;
    let settings = null;
    let isOpen = false;
    let isAjax = false;

    /**
     * Open dialog
     *
     * @param {Object} options
     * @param {string} options.data URL or HTML
     * @param {string} options.type `iframe`, `text` or `ajax`
     * @return {Object}
     */
    const open = (options) => {
        document.body.classList.add(settings.classBodyOpen);

        isOpen = true;
        elInner.innerHTML = '';

        if (options.type === 'iframe') {
            history.pushState(null, null, '');
            el.classList.add(settings.classTypeIframe);

            elInner.innerHTML = `<iframe src="${options.data}"
                width="100%" height="100%" frameborder="0"
                webkitallowfullscreen mozallowfullscreen allowfullscreen>
                </iframe>`;
        } else if (options.type === 'ajax') {
            if (!('ajaxSelector' in options)) {
                options.ajaxSelector = 'main'; // eslint-disable-line
            }

            const elTemp = document.createElement('div');
            isAjax = true;

            el.classList.add(settings.classLoading);
            el.classList.add(settings.classTypeHtml);

            _.loadData(options.data)
                .then(_.delayPromise(400))
                .then((data) => {
                    history.pushState(null, null, options.data);

                    el.classList.remove(settings.classLoading);

                    elTemp.innerHTML = data;
                    elInner.innerHTML = elTemp.querySelector(options.ajaxSelector).innerHTML;
                });
        } else {
            history.pushState(null, null, '');
            el.classList.add(settings.classTypeText);

            elInner.innerHTML = options.data;
        }

        el.classList.add(settings.classOpen);
        elInitialFocus = document.activeElement;

        return dialog;
    };

    /**
     * Close dialog
     *
     * @return {dialog}
     */
    const close = (setHistory = true) => {
        document.body.classList.remove(settings.classBodyOpen);

        if (setHistory && isAjax) {
            history.back();
        }

        isOpen = false;
        isAjax = false;
        el.classList.remove(settings.classOpen);

        if (elInitialFocus) {
            elInitialFocus.focus();
        }

        return dialog;
    };

    /**
     * Fired when user presses a key
     *
     * @private
     * @param  {Object} evt
     * @return {Void}
     */
    const handleKeyDown = (evt) => {
        if (!isOpen) {
            return;
        }

        if (evt.keyCode === KEY_TAB) {
            const focusableLength = elFocusable.length - 1;

            if (focusableLength === 1) {
                evt.preventDefault();
            }

            if (evt.shiftKey) {
                if (document.activeElement === elFocusable[0]) {
                    elFocusable[focusableLength].focus();

                    evt.preventDefault();
                }
            } else if (document.activeElement === elFocusable[focusableLength]) {
                elFocusable[0].focus();

                evt.preventDefault();
            }
        } else if (evt.keyCode === KEY_ESC) {
            close();
        }
    };

    /**
     * Fired when user clicks inside dialog element
     *
     * @private
     * @param   {Object} evt
     * @return  {Void}
     */
    const handleClick = (evt) => {
        if (!evt.target.matches(`.${settings.classInner}, .${settings.classInner} *`) ||
            evt.target.matches(`.${settings.classClose}, .${settings.classClose} *`)) {
            close();
        }
    };

    /**
     * Focus element when transition ends
     *
     * @private
     * @param {Object} evt
     * @return {Void}
     */
    const handleTransitionEnd = (evt) => {
        if (evt.propertyName === 'transform') {
            if (isOpen) {
                elFocusable = el.querySelectorAll(`a[href], area[href], input:not([disabled]),
                    select:not([disabled]), textarea:not([disabled]),
                    button:not([disabled]), [tabindex="0"]`);

                elFocusable[0].focus();
            } else {
                elInner.innerHTML = '';

                el.classList.remove(settings.classTypeIframe);
                el.classList.remove(settings.classTypeHtml);
                el.classList.remove(settings.classTypeText);
                el.classList.remove(settings.classLoading);
            }
        }
    };

    /**
     * @param {Object} [options]
     * @return {Object}
     */
    const init = (container, options = {}) => {
        if (!_.isElement(container)) {
            return dialog;
        }

        settings = _.extend({}, defaults, options);

        el = container;
        elInner = el.querySelector(`.${settings.classInner}`);

        el.addEventListener('click', handleClick);
        el.addEventListener('transitionend', handleTransitionEnd);
        document.addEventListener('keydown', handleKeyDown);
        window.addEventListener('popstate', () => {
            if (isOpen) {
                close(false);
            }
        });

        /**
         * Prevent iOS from scrolling the body element. As of late 2016, `overflow: hidden`
         * doesn't work in Safari on the body or html element
         */
        if ('ontouchmove' in document.documentElement) {
            document.documentElement.addEventListener('touchmove', (evt) => {
                if (isOpen) {
                    evt.preventDefault();
                }
            });

            elInner.addEventListener('touchmove', (evt) => {
                if (isOpen) {
                    evt.stopPropagation();
                }
            });
        }

        return dialog;
    };

    return {
        init,
        open,
        close,
    };
})();

export default dialog;
