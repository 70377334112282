/*------------------------------------*\
    #FILTER
\*------------------------------------*/
/**
 * Simple filter class that only appends the active filter name to a string. CSS will then do all
 * the heavy lifting and style the items accordingly
 */
import _ from '../utility';

/**
 * Defaults
 */
const defaults = {
    /**
     * Default class names
     *
     * @type {string}
     */
    classFiltered: 'is-filtered',
    classFilter: 'js-filter-toggle',
    classFilterActive: 'is-active',

    /**
     * Default data attribute names
     *
     * @type {string}
     */
    dataFilter: 'filter',
    dataFilterName: 'filter-name',
};

export default class Filter {
    /**
     * @constructor
     * @param {Element} el
     * @param {Object} [options]
     * @return {Instance} this
     */
    constructor(el, options) {
        // Settings
        this._settings = _.extend({}, defaults, options);

        // Elements
        this.el = el;

        // Props
        this._filterString = '';
        this._isFiltered = false;

        // Binding
        this._handleClick = this._handleClick.bind(this);

        this._handleEventListener();

        return this;
    }

    /**
     * Destroy instance
     *
     * @return {Void}
     */
    destroy() {
        this._handleEventListeners(true);

        this._settings = null;
        this._handleClick = null;
        this.el = null;
    }

    /**
     * @param {string} filterName
     * @param {boolean} [remove=false]
     * @return {Instance} this
     */
    filter(filterName, remove = false) {
        if (remove) {
            this._filterString = this._filterString.replace(`${filterName},`, '');

            if (this._filterString.length === 0) {
                this.el.classList.remove(this._settings.classFiltered);
            }
        } else {
            if (this._filterString.length === 0) {
                this.el.classList.add(this._settings.classFiltered);
            }

            this._filterString += `${filterName},`;
        }

        this.el.setAttribute(`data-${this._settings.dataFilter}`, this._filterString);

        return this;
    }

    /**
     * Fired when user clicks inside `el`
     *
     * @private
     * @param {Object} evt
     * @return {Void}
     */
    _handleClick(evt) {
        if (evt.target.matches(`.${this._settings.classFilter},
      .${this._settings.classFilter} *`)) {
            const elClosest = evt.target.closest(`.${this._settings.classFilter}`);
            const dataFilter = elClosest.getAttribute(`data-${this._settings.dataFilterName}`);
            const removeFilter =
                elClosest.classList.contains(this._settings.classFilterActive);

            elClosest.classList.toggle(this._settings.classFilterActive);

            this.filter(dataFilter, removeFilter);
        }
    }

    /**
     * Add/Remove event listeners
     *
     * @private
     * @param {boolean} [remove=false]
     * @return {Void}
     */
    _handleEventListener(remove = false) {
        const eventType = remove ? 'removeEventListener' : 'addEventListener';

        this.el[eventType]('click', this._handleClick);
    }
}
